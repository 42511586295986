function getCookieValue(name) {
    var cookiePair = document.cookie
        .split(';')
        .map(function (val) {
            return val.trimStart();
        })
        .find(function (val) {
            return val.startsWith(name);
        });
    return cookiePair && cookiePair.substring(cookiePair.indexOf('=') + 1);
}

function getSession(config, storage) {
    function getId() {
        return (Math.random() + 1).toString(36).substring(6);
    }
    function getCampaign(keys) {
        if (!keys.length || !document.location.search) {
            return;
        }

        var query = new URLSearchParams(document.location.search);

        return keys.reduce(function (acc, key) {
            var queryValue = query.get(key);
            if (queryValue) {
                acc[key] = queryValue;
            }
            return acc;
        }, {});
    }

    var sessionStr = storage.getItem(config.session_data_key || 'session_data');
    var now = Date.now();

    if (sessionStr) {
        var session = JSON.parse(sessionStr);
        if (session.expires >= now) {
            return Object.assign({}, session, {
                expires: now + (config.session_duration || 30) * 60,
            });
        }
    }

    return {
        expires: now + (config.session_duration || 30) * 60,
        id: now + '.' + getId(),
        start: now,
        data: {
            landing_page: document.location.href,
            referrer: document.referrer,
            campaign: getCampaign(config.url_params),
        },
    };
}

function getContext(config, session) {
    return Object.entries(config).reduce(
        function (acc, keyValue) {
            acc[keyValue[0]] = keyValue[1]();
            return acc;
        },
        { session: session }
    );
}

function getIntegrations(config, session) {
    return Object.entries(config).reduce(function (acc, keyValue) {
        acc[keyValue[0]] = keyValue[1](session);
        return acc;
    }, {});
}

function addContextData(payload, context) {
    var user = context.user || analytics.user();

    payload.obj.context = Object.assign({}, payload.obj.context, {
        traits: user.traits(),
        active: user.id() != null,
        location: context.location,
        screen: context.screen,
        page: Object.assign({}, context.page, payload.obj.context.page),
        timezone: context.timezone,
        session_id: context.session.id,
        campaign: context.session.data.campaign,
    });
}

function registerMiddleware(sessionConfig, contextConfig, integrationsConfig) {
    var session = getSession(sessionConfig, localStorage);
    var context = getContext(contextConfig, session);

    analytics.addSourceMiddleware(function (ctx) {
        var payload = ctx.payload;
        var next = ctx.next;
        if (payload.obj.type === 'page') {
            context.page = Object.assign(
                {
                    name: payload.obj.name ? payload.obj.name : undefined,
                    category: payload.obj.category ? payload.obj.category : undefined,
                },
                payload.obj.properties ? payload.obj.properties : undefined
            );
        }

        addContextData(payload, context);

        var integrations = Object.assign(
            {},
            payload.obj.context.integrations,
            getIntegrations(integrationsConfig, session)
        );

        payload.obj.context.integrations = integrations;
        payload.obj.integrations = integrations;
        next(payload);
    });

    analytics.addDestinationMiddleware('Google Tag Manager', function (ctx) {
        var payload = ctx.payload;
        var next = ctx.next;
        payload.obj.properties.context = payload.obj.context;
        next(payload);
    });
}

var sessionConfig = {
    // storage key
    session_data_key: 'session_data',
    // in minutes
    session_duration: 30,
    // query params to check at session generation
    url_params: [
        'utm_id',
        'utm_campaign',
        'utm_term',
        'utm_content',
        'utm_source',
        'utm_medium',
        'utm_source_platform',
        'utm_creative_format',
        'utm_marketing_tactic',
        'gclid',
        'fbclid',
        'irclickid',
        'msclkid',
        'sscid',
        'ttclid',
    ],
};

var contextConfig = {
    timezone: function () {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    screen: function () {
        return {
            width: window.screen.width,
            height: window.screen.height,
        };
    },
    location: function () {
        return Object.assign(
            {
                country: getCookieValue('geo'),
            },
            JSON.parse(localStorage.currentLocation || '{}')
        );
    },
};
var integrationsConfig = {
    'Google Analytics': function (session) {
        var cookieValue = getCookieValue('_ga');
        var campaign = session.data.campaign;
        return {
            clientId: cookieValue
                ? getCookieValue('_ga').split('.').slice(-2).join('.')
                : undefined,
            gclid: campaign ? campaign.gclid : undefined,
        };
    },
    'Facebook Conversions API (Actions)': function (session) {
        var campaign = session.data.campaign;
        return {
            fbp: getCookieValue('_fbp'),
            fbclid: campaign ? campaign.gclid : undefined,
        };
    },
};

registerMiddleware(sessionConfig, contextConfig, integrationsConfig);
